import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import { graphql } from 'gatsby'
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const {t,i18n} = useTranslation()
  return (
    <Layout>
      <div className="main">
        <div className="error-content-wrap text-center">
          <div className="error-code">{t(404)}</div>
          <h1 className="error-message h3">{t("Page not found")}</h1>
          <p className="message-manual">
            {t("Maybe the URL is incorrect, or the page no longer exist.")}
          </p>
          <Link hrefLang={i18n.language} to="/" className="btn">
            {t("Return to home page")}
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
